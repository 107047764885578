<template>
  <div v-frag>
    <custom-alert/>
    <div class='title-wrap d-flex'>
      <v-img :src='imageLogo' class='card-image' max-width='140px'/>
      <v-card-title class='card-title'>
        Registration
      </v-card-title>
    </div>
    <v-card class='card-wrap mt-n6 pt-6 pb-16 px-11'>
      <v-form ref='form' v-model='isFormValid'>
        <v-container>
          <v-row class='form-row' md='4' sm='6'>
            <v-col class="form-col">
              <p class='font-weight-bold'>First Name</p>
              <v-text-field
                v-model='formData.first_name'
                :rules="[(v) => !!v && !!v.trim() || 'Field is required']"
                dense
                outlined
                placeholder='i.e John'
              ></v-text-field>
            </v-col>
            <v-col class="form-col">
              <p class='font-weight-bold'>Middle Name</p>
              <v-text-field
                v-model='formData.second_name'
                dense
                outlined
                placeholder='i.e Phill'
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class='form-row' md='4' sm='6'>
            <v-col class="form-col">
              <p class='font-weight-bold'>Last Name</p>
              <v-text-field
                v-model='formData.last_name'
                :rules="[(v) => !!v && !!v.trim() || 'Field is required']"
                dense
                outlined
                placeholder='i.e Williams'
              ></v-text-field>
            </v-col>
            <v-col class="form-col">
              <p class='font-weight-bold'>Birth Year</p>
              <year-picker v-model='formData.birth_date' placeholder='i.e 1970'/>
            </v-col>
          </v-row>
          <v-row class='form-row' md='4' sm='6'>
            <v-col class="form-col">
              <p class='font-weight-bold'>Mobile Phone</p>
              <v-text-field
                v-model='formData.mobile_phone'
                :rules='phoneRules'
                dense
                outlined
                placeholder='i.e 2123456789'
              ></v-text-field>
            </v-col>
             <v-col class="form-col">
              <p class='font-weight-bold'>Address</p>
              <v-text-field
                id='addressInput'
                ref='addressInput'
                v-model='formData.address'
                :rules="[(v) => !!v && !!v.trim() || 'Field is required']"
                dense
                outlined
                placeholder='i.e Lincoln Square 123'
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class='form-row' md='4' sm='6'>
             <v-col class="form-col">
              <p class='font-weight-bold'>City</p>
              <v-text-field
                id='cityInput'
                ref='cityInput'
                v-model='formData.city'
                :rules="[(v) => !!v && !!v.trim() || 'Field is required']"
                dense
                outlined
                placeholder='i.e Chicago'
                required
              ></v-text-field>
            </v-col>
             <v-col class="form-col">
              <p class='font-weight-bold'>State</p>
              <v-text-field
                id='stateInput'
                ref='stateInput'
                v-model='formData.state'
                :rules="[(v) => !!v && !!v.trim() || 'Field is required']"
                dense
                outlined
                placeholder='i.e Texas'
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class='form-row' md='4' sm='6'>
             <v-col class="form-col">
              <p class='font-weight-bold'>Zip Code</p>
              <v-text-field
                id='zipCodeInput'
                ref='zipCodeInput'
                v-model='formData.zip_code'
                :rules="[(v) => !!v && !!v.trim() || 'Field is required']"
                dense
                outlined
                placeholder='i.e 9851123'
                required
              ></v-text-field>
            </v-col>
             <v-col class="form-col">
              <p class='font-weight-bold'>Primary Email</p>
              <v-text-field
                v-model='formData.email'
                :rules='emailRules'
                dense
                outlined
                placeholder='i.e john@mydomain.com'
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-btn
          :loading='loadingSubmit'
          class='px-14 ma-4 my-10 font-weight-bold white--text'
          color='primary'
          large
          @click='onSubmit'
        >
          Register
        </v-btn>
      </v-form>
    </v-card>
    <v-dialog
      v-model='isSuccessDialogOpen'
      width='400'
    >
      <v-card class='d-flex justify-center flex-column align-center pa-2'>
        <v-card-title>
          <v-icon class='mr-2' color='success'>mdi-checkbox-marked-circle-outline</v-icon>
          <span>Welcome to MCBA!</span>
        </v-card-title>
        <v-card-text class='text-center'>
          Please check your email to activate your new membership.
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import YearPicker from '@/components/year-picker'
import image from '@/assets/images/mcbalogo3.png'
import CustomAlert from '@/views/employee/components/CustomAlert'

export default {
  name: 'RegistrationPage',
  components: { CustomAlert, YearPicker },
  data: () => ({
    imageLogo: image,
    isFormValid: false,
    formData: {},
    loadingSubmit: false,
    addressRules: [(v) => !!v || 'Address is required'],
    cityRules: [(v) => !!v || 'City is required'],
    zipCodeRules: [(v) => !!v || 'Zip code is required'],
    emailRules: [
      v => !!v || 'Field is required',
      v => /.+@.+\..+/.test(v) || 'The email format is not correct'
    ],
    phoneRules: [
      v => v ? ((/^[0-9]+$/.test(v) && v?.length === 10) || 'Value must be a number and be equal to 10 characters') : true
    ],
    isSuccessDialogOpen: false
  }),
  methods: {
    ...mapActions('employeeStore', ['sendRegistrationEmployee']),
    ...mapMutations('employeeStore', ['SET_CUSTOM_ALERT_DATA']),
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loadingSubmit = true
        this.SET_CUSTOM_ALERT_DATA({
          show: true,
          color: 'green',
          message: 'The account is registering, this process takes 2-5 minutes.',
          timeout: 300000
        })
        await this.sendRegistration()
      }
    },
    async sendRegistration() {
      const data = {
        ...this.formData,
        employee_role_id: 4,
        member_id: +this.$route.query.member_id,
        opt_in: true
      }
      try {
        const res = await this.sendRegistrationEmployee(data)
        if (res.data) {
          this.isSuccessDialogOpen = true
        }
      } catch (e) {
        this.loadingSubmit = false
      }
      this.loadingSubmit = false
      this.SET_CUSTOM_ALERT_DATA({
        show: false,
        color: 'green',
        message: ''
      })
    },
  },
  watch: {
    async isSuccessDialogOpen(newQuestion, oldQuestion) {
      if ( !newQuestion && oldQuestion) {
        await this.$router.replace({name: 'Login'})
      }
    }
  }
}
</script>

<style scoped>
.title-wrap{
  background: #5E8FD5;
  height: 180px;
  @media (min-width: 960px) {
    padding: 6px 165px 68px;
  }

  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
  }
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  @media (max-width: 960px) {
    //box-shadow: none;
    //border-top: solid black;
  }
}
.card-title{
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
}

.card-wrap {
  @media (min-width: 960px) {
    margin-top: -56px;
    margin-left: 125px;
    margin-right: 125px;
    border-radius: 15px;
  }
}
.form-row {
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    //margin-bottom: 20px;
  }
}
.form-col{
  margin-bottom: -16px;
  @media (max-width: 960px) {
    margin-bottom: -32px;
  }
}
</style>
